import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./components/contact";
import Login from "./components/login";
import Home from "./components/home";
import Stripe from "./components/stripe";

// Initialize Firebase

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/payment" element={<Stripe />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
