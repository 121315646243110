import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const date = new Date();
  let year = date.getFullYear();
  return (
    <>
      <header
        className="header-area header-sticky wow slideInDown"
        data-wow-duration="0.75s"
        data-wow-delay="0s"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                <a href="index.html" className="logo">
                  <img
                    src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/logo-k.jpeg"
                    alt="Kreate Logo"
                  />
                </a>
                <ul className="nav">
                  <li className="scroll-to-section">
                    <a href="#top" className="active">
                      Home
                    </a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#features">Features</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#about">About</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#pricing">Pricing</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#newsletter">Newsletter</a>
                  </li>
                  <li>
                    <div className="gradient-button">
                      <Link to={"/login"}>
                        <i className="fa fa-sign-in-alt"></i> Sign In Now
                      </Link>
                    </div>
                  </li>
                </ul>
                <a className="menu-trigger">
                  <span>Menu</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>

      {/* <div id="modal" className="popupContainer">
        <div className="popupHeader">
          <span className="header_title">Login</span>
          <span className="modal_close">
            <i className="fa fa-times"></i>
          </span>
        </div>

        <section className="popupBody">
          <div className="social_login">
            <div className="">
              <a href="#" className="social_box fb">
                <span className="icon">
                  <i className="fab fa-facebook"></i>
                </span>
                <span className="icon_title">Connect with Facebook</span>
              </a>

              <a href="#" className="social_box google">
                <span className="icon">
                  <i className="fab fa-google-plus"></i>
                </span>
                <span className="icon_title">Connect with Google</span>
              </a>
            </div>

            <div className="centeredText">
              <span>Or use your Email address</span>
            </div>

            <div className="action_btns">
              <div className="one_half">
                <a href="#" id="login_form" className="btn">
                  Login
                </a>
              </div>
              <div className="one_half last">
                <a href="#" id="register_form" className="btn">
                  Sign up
                </a>
              </div>
            </div>
          </div>

          <div className="user_login">
            <form>
              <label>Email / Username</label>
              <input type="text" />
              <br />

              <label>Password</label>
              <input type="password" />
              <br />

              <div className="checkbox">
                <input id="remember" type="checkbox" />
                <label for="remember">Remember me on this computer</label>
              </div>

              <div className="action_btns">
                <div className="one_half">
                  <a href="#" className="btn back_btn">
                    <i className="fa fa-angle-double-left"></i> Back
                  </a>
                </div>
                <div className="one_half last">
                  <a href="#" className="btn btn_red">
                    Login
                  </a>
                </div>
              </div>
            </form>

            <a href="#" className="forgot_password">
              Forgot password?
            </a>
          </div>

          <div className="user_register">
            <form>
              <label>Full Name</label>
              <input type="text" />
              <br />

              <label>Email Address</label>
              <input type="email" />
              <br />

              <label>Password</label>
              <input type="password" />
              <br />

              <div className="checkbox">
                <input id="send_updates" type="checkbox" />
                <label for="send_updates">
                  Send me occasional email updates
                </label>
              </div>

              <div className="action_btns">
                <div className="one_half">
                  <a href="#" className="btn back_btn">
                    <i className="fa fa-angle-double-left"></i> Back
                  </a>
                </div>
                <div className="one_half last">
                  <a href="#" className="btn btn_red">
                    Register
                  </a>
                </div>
              </div>
            </form>
          </div>
        </section> */}
      {/* </div> */}

      <div
        className="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center">
                  <div
                    className="left-content show-up header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <h2>
                          Generate websites - Publish blog at scale and get
                          found on Google
                        </h2>
                        <p>
                          Kreatewebsites let you publish content at speed -
                          text, slides, code, pdf - convert to web pages as a
                          web designer will do. You can choose to generate
                          content too using OpenAI or bard
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="white-button first-button scroll-to-section">
                          <a href="#contact">
                            Free Quote <i className="fab fa-apple"></i>
                          </a>
                        </div>
                        <div className="white-button scroll-to-section">
                          <a href="#contact">
                            Free Quote <i className="fab fa-google-play"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="right-image wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <img
                      src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/slider-dec.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="features" className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div
                className="section-heading  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <h4>
                  Amazing <em>Features &amp; Services</em> for you
                </h4>
                <img
                  src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/heading-line-dec.png"
                  alt=""
                />
                <p>Just provide content, or path to content or prompts. </p>
                <p>
                  It is different experience - there is no UI to design website,
                  system will generate website and knobs using which you can
                  fine tune site if you wish too
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="service-item first-service">
                <div className="icon"></div>
                <h4>E2E management</h4>
                <p>
                  Web design generation, content integration, SEO, deployment,
                  monitoring are all included in plan
                </p>
                <div className="text-button">
                  <a href="#">
                    Read More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item second-service">
                <div className="icon"></div>
                <h4>Go to market faster</h4>
                <p>
                  Reach to your audience at speed. Engage regularly by
                  publishing as you build content.
                </p>
                <div className="text-button">
                  <a href="#">
                    Read More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item third-service">
                <div className="icon"></div>
                <h4>Generate variants</h4>
                <p>
                  Using Kreate you can generate multiple variants. A human can
                  pick the winner design or you can A/B test directly
                </p>
                <div className="text-button">
                  <a href="#">
                    Read More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item fourth-service">
                <div className="icon"></div>
                <h4>Have Questions &amp; Want to See Examples</h4>
                <p>Read our blog, Q&A. See various sites build using KREATE</p>
                <div className="text-button">
                  <a href="#">
                    Read More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="section-heading">
                <h4>
                  About <em>What We Do</em> &amp; Who We Are
                </h4>
                <img
                  src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/heading-line-dec.png"
                  alt=""
                />
                <p>KREATE is AI tool that geenrate web design. </p>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href="#">Generate from content</a>
                    </h4>
                    <p>
                      Just point to content - images, text, power point, google
                      slides, vide links and it will generate web pages
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href="#">24/7 Support &amp; Help</a>
                    </h4>
                    <p>
                      Our sites run on cloud and monitored. Send email if there
                      is issue and we will respond as per SLA.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href="#">Fixing Issues About</a>
                    </h4>
                    <p>
                      Our SLA to fix issue, design change is 24 hr, If there is
                      downtime it looked into immediately
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href="#">Custom Development</a>
                    </h4>
                    <p>
                      We genrate knobs using which custom development , changes
                      can be done at fast pace.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <p>
                    Automated web designing suitable for all form factors, AI
                    generated SEO, 99.9% availability high speed websites{" "}
                  </p>
                  <div className="gradient-button">
                    <a href="#">14-Day Money back guarantee</a>
                  </div>
                  <span>
                    You can ask cancel services and refund, no question ask
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-image">
                <img
                  src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/about-right-dec.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="clients" className="the-clients">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-heading">
                <h4>
                  Check how<em>Various websites</em> are running on
                  kreatewebsites cloud
                </h4>
                <img
                  src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/heading-line-dec.png"
                  alt=""
                />
                <p>USA City Trip has published thousands of pages </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="naccs">
                <div className="grid">
                  <div className="row">
                    <div className="col-lg-7 align-self-center">
                      <div className="menu">
                        <div className="first-thumb active">
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-4 col-sm-4 col-12">
                                <h4>usacitytrip.com</h4>
                                <span className="date">30 November 2021</span>
                              </div>
                              <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                                <span className="category">
                                  Travel blog for USA
                                </span>
                              </div>
                              <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating">4.8</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-4 col-sm-4 col-12">
                                <h4>daynightcricket.com</h4>
                                <span className="date">29 November 2021</span>
                              </div>
                              <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                                <span className="category">
                                  Sports site to show cricket score
                                </span>
                              </div>
                              <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating">4.5</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-4 col-sm-4 col-12">
                                <h4>europecitytrip.com</h4>
                                <span className="date">27 November 2021</span>
                              </div>
                              <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                                <span className="category">
                                  euro[e] &amp; tour
                                </span>
                              </div>
                              <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating">4.7</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-4 col-sm-4 col-12">
                                <h4>Cricket app</h4>
                                <span className="date">Android app</span>
                              </div>
                              <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                                <span className="category">
                                  See summary of cricket
                                </span>
                              </div>
                              <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="last-thumb">
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-4 col-sm-4 col-12">
                                <h4>Abexperiment.com</h4>
                                <span className="date">Ab testing book</span>
                              </div>
                              <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                                <span className="category">
                                  Technology blog and book
                                </span>
                              </div>
                              <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <ul className="nacc">
                        <li className="active">
                          <div>
                            <div className="thumb">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="client-content">
                                    <img
                                      src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/quote.png"
                                      alt=""
                                    />
                                    <p>
                                      “Every few years new devices will come
                                      e.g. foldable phone"
                                      <br />
                                      Every few months SEO algorithm will
                                      change.
                                      <br />
                                      Everyweek business will want to make
                                      changes in websites
                                      <br />
                                      KREATE regenerate websites according to
                                      specification, devices and SEO. <br />
                                      It uses knobs that allow you to specify
                                      and control and e2e web apps are
                                      regenerated.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li>
                          <div></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="pricing" className="pricing-tables">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-heading">
                <h4>
                  We Have The Best Pre-Order <em>Prices</em> You Can Get
                </h4>
                <img
                  src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/heading-line-dec.png"
                  alt=""
                />
                <p>
                  Set up your account and share email address. We will reach out
                  to you to point domain to hosting. Then you can start publish
                  contentc
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="pricing-item-regular">
                <span className="price">$10/month</span>
                <h4>Standard Plan App</h4>
                <div className="icon">
                  <img
                    src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/pricing-table-01.png"
                    alt=""
                  />
                </div>
                <ul>
                  <li>Best for self employeed</li>
                  <li>Custom domain</li>
                  <li>Storage 4 GB</li>
                  <li>Free SSL</li>
                  <li className="non-function">Premium Add-Ons</li>
                  <li className="non-function">Priority customer support</li>
                  <li className="non-function">More Options</li>
                </ul>
                <div className="border-button">
                  <a href="#">Purchase This Plan Now</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="pricing-item-pro">
                <span className="price">$19/month</span>
                <h4>Startup Plan</h4>
                <div className="icon">
                  <img
                    src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/pricing-table-01.png"
                    alt=""
                  />
                </div>
                <ul>
                  <li>Best for small business</li>
                  <li>Custom domain</li>
                  <li>Storage 8 GB</li>
                  <li>Free SSL</li>
                  <li>Unlimited pages</li>
                  <li>Premium Add-Ons</li>
                  <li className="non-function">Priority customer support</li>
                  <li className="non-function">More Options</li>
                </ul>
                <div className="border-button">
                  <a href="#">Purchase This Plan Now</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="pricing-item-regular">
                <span className="price">$39/month</span>
                <h4>Premium Plan App</h4>
                <div className="icon">
                  <img
                    src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/pricing-table-01.png"
                    alt=""
                  />
                </div>
                <ul>
                  <li>Publish with prompts</li>
                  <li>Custom domain</li>
                  <li>Storage 100 GB</li>
                  <li>Free SSL</li>
                  <li>Unlimited pages</li>
                  <li>Premium Add-Ons</li>
                  <li>Analytics Integration</li>
                  <li>More Options</li>
                </ul>
                <div className="border-button">
                  <a href="#">Purchase This Plan Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer id="newsletter">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-heading">
                <h4>
                  Join our mailing list to receive the news &amp; latest trends
                </h4>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-3">
              <form id="search" action="#" method="GET">
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <fieldset>
                      <input
                        type="address"
                        name="address"
                        className="email"
                        placeholder="Email Address..."
                        autocomplete="on"
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <fieldset>
                      <button type="submit" className="main-button">
                        Subscribe Now <i className="fa fa-angle-right"></i>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="footer-widget">
                <p>
                  <Link to="/contact">Contact Us</Link>
                </p>
                <p>Redmond, WA, USA</p>
                <p>
                  <a href="#">1 4255039115</a>
                </p>
                <p>
                  <a href="#">info@kreatewebsites.com</a>
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget">
                <h4>About Us</h4>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">Features</a>
                  </li>
                  <li>
                    <a href="#">About</a>
                  </li>
                  <li>
                    <a href="#">Examples</a>
                  </li>
                  <li>
                    <a href="#">Pricing</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="#">About</a>
                  </li>
                  <li>
                    <a href="#">Examples</a>
                  </li>
                  <li>
                    <a href="#">Pricing</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <a href="#">Free Apps</a>
                  </li>
                  <li>
                    <a href="#">App Engine</a>
                  </li>
                  <li>
                    <a href="#">Programming</a>
                  </li>
                  <li>
                    <a href="#">Development</a>
                  </li>
                  <li>
                    <a href="#">App News</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="#">App Dev Team</a>
                  </li>
                  <li>
                    <a href="#">Digital Web</a>
                  </li>
                  <li>
                    <a href="#">Normal Apps</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget">
                <h4>About Our Company</h4>
                <div className="logo">
                  <img
                    src="https://storage.googleapis.com/kreatewebsites/site101/assets/images/logo-k.jpeg"
                    alt=""
                  />
                </div>
                <p>Generate , automate and publish at scale</p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="copyright-text">
                <p>
                  Copyright © 2022 KREATE. All Rights Reserved.
                  <br />
                  Design:{" "}
                  <a
                    href="https://www.kreatewebsites.com/"
                    target="_blank"
                    title="css templates"
                    rel="noreferrer"
                  >
                    Kreate websites
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Home;
