import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import Form from "./form";

const PUBLIC_KEY = "pk_test_rgWMA3zxjAtwaB6iV8b5W40x";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function Stripe() {
  return (
    <Elements stripe={stripeTestPromise}>
      <Form />
    </Elements>
  );
}
