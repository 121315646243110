// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDy2QiI4o9Vwe6OsmCmCXWpntAVoa6VQ0c",
  authDomain: "kreate-ffc7c.firebaseapp.com",
  projectId: "kreate-ffc7c",
  storageBucket: "kreate-ffc7c.appspot.com",
  messagingSenderId: "509206339975",
  appId: "1:509206339975:web:284f445d1ce2fdcfb9f322",
  measurementId: "G-R0CXX5EPPC",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth();
export const database = getDatabase(app);
const analytics = getAnalytics(app);
