import React, { useEffect } from "react";
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import "firebaseui/dist/firebaseui.css";
import { auth } from "../../Firebase";

function Login() {
  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start("#firebaseui-auth-container", {
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      signInFlow: "popup",
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      tosUrl: "https://rabbitloader.com/terms/",
      privacyPolicyUrl: "https://rabbitloader.com/privacy/",
      callbacks: {
        signInSuccessWithAuthResult: async (currentUser) => {
          if (currentUser) {
            console.log(currentUser);
          }
          return false;
        },
      },
    });
  });
  return <div id="firebaseui-auth-container"></div>;
}

export default Login;
